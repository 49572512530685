import { createTheme } from "@mui/material";

// declare module '@mui/material/styles' {
//     interface Theme {
//       status: {
//         danger: string;
//       };
//     }
//     // allow configuration using `createTheme`
//     interface ThemeOptions {
//       status?: {
//         danger?: string;
//       },
//       props?: {

//       }
//     }
//   }

export default createTheme({
    palette: {
        mode: 'dark',
        common: {
            // glass: "#f4f4f4"
        },
        primary: {
            main: "rgb(0,2,45)"
        },
        background: {
            default: 'rgb(0,2,45)',
          },
    },
    
  })