import { useEffect, useState } from 'react';
import './App.css';
import { Container, CssBaseline, Divider, Fade, Link, Paper, Typography } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import theme from './app/theme';

function App() {
  const quotes = () => [
    '"Our filing system is an Excel spreadsheet, made by someone who left five years ago."',
    '"We make new copies each week and store them in a shared folder."',
    '"Is that the reference number from the finance system or the document management system?"',
    '"It gets it right 95% of the time, and we spend 80% of our time fixing the other 5%."',
    'Re: Re: Fwd: Big Client Proposal v4 FINAL (Revised) 2 - Taylor\'s Version.docx',
    '"It works fine on MY machine"',
    '"Why does my laptop have six different PDF printers?"',
    '"All our teams have different ways of doing things."',
    '"We have a Sharepoint licence but we don\'t really use it properly."'
  ];

  const [quoteIndex, setQuoteIndex] = useState(Math.floor(Math.random() * quotes().length));
  const [fadeIn, setFadeIn] = useState(true);
  const [logoFont, setLogoFont] = useState("Monoton");
  const [toadpacity, setToadpacity] = useState(0);

  const fonts = () => 'Tangerine|Open Sans|Monoton|Mrs Sheppards|Licorice|Ole|Devonshire|Eagle Lake|Molle|Inspiration'.split('|');

  useEffect(() => {
    if (toadpacity < 0.03) window.setTimeout(() => {
      setToadpacity(toadpacity + 0.001);
      console.log(toadpacity);
    }, 5000);
  }, [toadpacity]);

  useEffect(() => {
    setFadeIn(true);

    const q = quotes();

    const timerLength = 5000;

    setLogoFont(() => fonts()[Math.floor(Math.random() * fonts().length)]);
    // console.log(logoFont);
    window.setTimeout(() => {
      if (quoteIndex < q.length - 1)
        setQuoteIndex((q) => q + 1);
      else
        setQuoteIndex(() => 0);

      // console.log(quoteIndex);
    }, timerLength);

    window.setTimeout(() => {
      setFadeIn(false);
    }, timerLength - 500);
    
  }, [ quoteIndex ]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container disableGutters color="primary" maxWidth="md" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          variant="h1"
          align="center"
          fontFamily={logoFont}
          title={logoFont}
          sx={{ color: 'hsla(237,10%,100%, 0.25)' }}
        >
          sublimi
          <span style={{ color: 'hsla(40,50%,50%, 0.5)' }}>.</span>
          nl
        </Typography>
        <Typography variant='h5' align='center' gutterBottom sx={{ marginTop: '-0.7em' }}>
          software solutions
        </Typography>
        <Typography align='center'>
          {['web', 'desktop', 'workflows', 'automation', 'integration'].map(
            x => <span key={x} style={{ margin: '1rem' }}>{x}</span>
          )}
        </Typography>
        <Paper sx={{ minHeight: '7rem', marginTop: '2rem', padding: '1rem', background: 'hsla(237,10%,30%, 0.8)', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
          <Fade in={fadeIn} timeout={500} >
            <Typography variant='body1' align='center' sx={{ fontSize: '1.2rem', fontFamily: 'Open Sans', fontStyle: 'italic', minHeight: '3em', verticalAlign: 'middle' }}>
              {quotes()[quoteIndex]}
            </Typography>
          </Fade>
        </Paper>
        <Typography variant='h6' align='center'>
          Is this you?
        </Typography>
        <Typography variant='h6' align='center'>
          We can do better.
        </Typography>
        <Typography variant='h6' align='center'>
          Get a custom software solution.
        </Typography>
        <Typography variant='h6' align='center'>
          Get the most out of your infrastructure.
        </Typography>
        <Typography variant='h6' align='center'>
          Help your human resources add their best value.
        </Typography>
        <Divider sx={{ margin: "2rem 0" }}></Divider>
        <Typography variant='h6' align='center'>
          <Link underline='hover' color="inherit" href='mailto:info@sublimi.nl'>info@sublimi.nl</Link>
        </Typography>
        <Divider sx={{ margin: "2rem 0" }}></Divider>
        <Typography>
          It was the lorem of times, it was the ipsum of times.
        </Typography>
        <img alt='' src='/hypnotoad.gif' style={{
          position: 'absolute',
          width: '100%',
          top: 0,
          left: 0,
          zIndex: -1,
          opacity: toadpacity,
        }} />
      </Container>
    </ThemeProvider>
  );
}

export default App;
